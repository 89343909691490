import React, { FC, useState } from 'react'
import Button from '@material-ui/core/Button';

import Papa from 'papaparse' 
import './App.css'
import FileUpload from './FileUpload'
import Chart from './Chart'

interface ChartRecord {
  weekhour: number;
  actual: number;
  average: number;
  UCL: number;
  LCL: number;
  UCL99: number;
  LCL1: number;
  lasthour: number
}

const App: FC = () => {
 
  const [chartData, setChartData] = useState({})
  const [tableData, setTableData] = useState([])
  const [step, setStep] = useState('home')
  const [highAnomalyCount, setHighAnomalyCount] = useState(undefined)
  const [lowAnomalyCount, setLowAnomalyCount] = useState(undefined)

  const goHome = () => {
    setStep('home')
  }

  const handleFileUpload = (file: File) => {
    processUploadedFile(file) 
    setStep('chart')
  }
  
  const postData = async (data: any) => {
    const getResponse = await fetch("https://perfomalist.trutechdev.now.sh/api/stats", { 
      method: 'POST',
      body: JSON.stringify(data), 
      headers: { 
        "Content-Type": "text/plan" 
      }
    })

    return getResponse.json()
  }

  const processUploadedFile = (file: File): {errors: object, data: object} => {
    return Papa.parse(file, {
      complete: (result: {errors: Array<object>, data: object}) =>  {
        if (result.errors.length > 0) {
          console.log('file upload errors', result.errors)
        } else {
          postData(result.data)
          .then(stats => {
            const chartData = getChartData(stats)
            setChartData(chartData)
            setTableData(getTableData(stats))
          })
          .catch(error => console.log(error))
        } 
      }
    })
  }

  const getTableData = (data: any) => {    
    let haCount = 0
    let laCount = 0
    
    const dataWithEV = data.map(datapoint => {
      // TODO: API calls it 99 and 1 but it's actually 95 and 5, need to correct 
      datapoint.evPlus = datapoint.actual > datapoint.UCL99
        ? (datapoint.actual - datapoint.UCL99).toFixed(3)
        : 0
       datapoint.evMinus = datapoint.actual < datapoint.LCL1
        ? (datapoint.LCL1 - datapoint.actual).toFixed(3)
        : 0
      
      if (datapoint.actual > datapoint.UCL99) {
        haCount = haCount + 1
      }
      if (datapoint.actual < datapoint.LCL1) {
        laCount = laCount + 1
      }
      
      datapoint.actual = parseFloat(datapoint.actual.toFixed(3)).toLocaleString('en') 
      datapoint.average = parseFloat(datapoint.average.toFixed(3)).toLocaleString('en') 
      datapoint.UCL = parseFloat(datapoint.UCL.toFixed(3)).toLocaleString('en') 
      datapoint.LCL = parseFloat(datapoint.LCL.toFixed(3)).toLocaleString('en') 
      datapoint.LCL1 = parseFloat(datapoint.LCL1.toFixed(3)).toLocaleString('en') 
      datapoint.UCL99 = parseFloat(datapoint.UCL99.toFixed(3)).toLocaleString('en') 
      datapoint.evPlus = parseFloat(datapoint.evPlus).toLocaleString('en') 
      datapoint.evMinus = parseFloat(datapoint.evMinus).toLocaleString('en') 
      
      return datapoint  
    })
    
    setHighAnomalyCount(haCount)
    setLowAnomalyCount(laCount)
    
    return dataWithEV
  }
  
  const getChartData = (data: ChartRecord[]) => {
    // const labels = data.map((e: ChartRecord) => e.weekhour)
    const actual = data.map((e: ChartRecord) => e.actual)
    const average = data.map((e: ChartRecord) => e.average)
    const UCL = data.map((e: ChartRecord) => e.UCL)
    const LCL = data.map((e: ChartRecord) => e.LCL)
    const UCL99 = data.map((e: ChartRecord) => e.UCL99)
    const LCL1 = data.map((e: ChartRecord) => e.LCL1)
    const lasthour = data.map((e: ChartRecord) => e.lasthour)

    const labels = []
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    days.forEach(e => {
      labels.push(e)
      for (let i = 1; i < 24; i++) {
        labels.push(i + 'h')
      }
      
    })

    let datasets = [{
      label: "actual",
      data: actual,
      borderColor: "rgba(255, 159, 64,.65)"
    },
    {
      label: "average",
      data: average,
      borderColor: "rgba(153, 102, 255,.65)",
    },
    {
      label: "UCL",
      data: UCL,
      borderColor: "rgba(16,116,24,.65)",
    },
    {
      label: "LCL",
      data: LCL,
      borderColor: "rgba(255, 99, 132, .65)",
    },
    {
      label: "UCL95",
      data: UCL99,
      borderColor: "rgba(54, 162, 235, .65)",
    },
    {
      label: "LCL5",
      data: LCL1,
      borderColor: "rgba(75, 192, 192, .65)",
    },
    {
      label: "Last Hour",
      data: lasthour,
      borderColor: " rgba(0, 0, 0, 1)",
      backgroundColor: "rgba(33, 145, 81, 0.2)",
    }]
    datasets = datasets.map(d => {
      const dataset = Object.assign(d, {
        borderWidth: 1.5,
        radius: 1.5,
        pointStyle: "line",
        fill: false  
      })
  
      return dataset
    })

    return {
      labels,
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Week hour'
          }
        }]
      },
      datasets
    }
  }

  return (
    <>
      <div style={{marginBottom: '20px', borderBottom: '1px solid #ccc'}}>
   
      <h1 style={{float: 'left', margin: '0 20px 0 0'}}>
        PERFOMALIST
      </h1>
         <img alt="Perfomalist" src="/TTD.jpg" style={{marginTop: '-27px'}} />
      </div>
      { step === 'home' &&
        <>
          <h2>
            Weekly Data Profiler, Anomalies and Change Points Detector
          </h2>

          <p>
             <mark>NEW!</mark> Change point detection is now available for your data via API (beta version) <a href='https://www.trutechdev.com/2021/11/the-change-points-detection-perfomalapi.html'>HERE</a>
          </p>
          <h4>Functionality</h4>
          <p>
            VISUALIZATION. Weekly  data profiling to visualize patterns, anomalies and short term seasonality via IT-Control Charts.
          </p>

          <h4>Method</h4>
          <p>
            <a href="http://www.trub.in/2012/11/setds-methodology-cmg12-speech.html">
              SPC/MASF based Statistical Exception and Trend Detection (SETDS)
            </a>
          </p>

          <h4>Output</h4> 
          <p>
            IT-Control Chart (see example below)
            Data cube with summarized data (168 rows/weekhours)
          </p>
            
          <h4>Requirement</h4>
          <p>
            Input data should consist of at least 3 weeks of history as the method requires comparing the last 7 days of data (actual) with at least 2 weeks long learning/reference data set (baseline). 
          </p> 

          <h4>Additional resources:</h4>
          <ul style={{listStyleType: 'none'}}>
            <li>
              <a href="http://www.trub.in/2013/12/review-of-it-control-chart-my-new-paper.html">
                How to read IT-Control Chart (online article)
              </a>
            </li>
            <li>
              <a href="http://www.trub.in/2018/04/performance-anomalydetection-online.html">
                Online class "Performance Anomaly Detection"
              </a>
            </li>
          </ul>

          <h4>IT-Control Chart example (with anomaly detected):</h4>
          <p>  
            <a href="/itchart.png" target="_blank">
              <img alt="IT-Chart example" src="/itchart.png" style={{width: '400px'}} />
            </a>
          </p>

          <Button 
            variant="contained"
            size="large"
            color="primary"
            onClick={() => setStep('upload')}
            style={{marginTop: '20px'}}
          >
            Try it now!
          </Button>  
        </>
      }

      { step === 'upload' && 
        <FileUpload 
          onFileUpload={handleFileUpload}
          onClose={goHome}
        />
      }

      { step === 'chart' && highAnomalyCount && lowAnomalyCount &&
        <Chart 
          chartData={chartData}
          tableData={tableData}
          onClose={goHome}
          metadata={{
            highAnomalyCount,
            lowAnomalyCount
          }}
        />
      }
      
      <div style={{marginTop: '20px'}}> 
        <a href = "mailto:TruTechDev@gmail.com?subject=Feedback" > 
          Send Feedback
        </a>
      </div>  
    </>
  )
}

export default App
