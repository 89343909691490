import React, { FC, ChangeEvent, useState } from 'react'
import Button from '@material-ui/core/Button'

interface Props {
  onFileUpload: (file: File) => void;
  onClose: () => void;
}

const FileUpload: FC<Props> = ({
  onFileUpload,
  onClose
}) => {
  const [file, setFile] = useState()

  const handleSelectFileChange = (event: ChangeEvent) => {
    const target= event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setFile(file)
  }

  const handleFileUpload = () => {
    onFileUpload(file)
  }

  return (
    <>
      <h2 style={{display: 'inline-block'}}>
        Upload observation records
      </h2>
      <Button 
        variant="outlined"
        onClick={onClose}
        style={{display: 'inline-block', marginLeft: '10px'}}
      >
        Back
      </Button>

      <p>
        Learn how to read IT-Chart <a href="http://www.trub.in/2013/12/review-of-it-control-chart-my-new-paper.html">here</a> 
      </p>

      <p>
        Read a full tutorial <a href="http://www.trub.in/2018/04/performance-anomalydetection-online.html">here</a>
      </p>

      <p>
        Input file format is 3-column comma-separated file with date, hour, value. 
      </p>

      <p>  
        The number of observations records should be more that 3 weeks worth. See data sample below. 
      </p><br/>
      
      <img src="/input.PNG" alt='example'/><br/>
      <br/>
      <a href="/sample-upload.csv" target="_blank">
              Download Input Data Sample
      </a> 
      <br/><br/>
      {
        file && (
          <div>
            <div style={{marginBottom: '10px'}}>
              <strong >
                {file.name}
              </strong>
            </div>

            <div>
              <Button
                variant="contained"
                size="small" 
                color="primary"
                onClick={handleFileUpload}
                style={{marginRight: '20px'}}
              >
                Upload
              </Button>

              <Button
                variant="outlined"
                size="small" 
                onClick={() => setFile(null)}
              >
                Cancel
              </Button>
            </div>
          </div>
        )
      }
      { 
        !file && ( 
          <>
            <label htmlFor='file'>
              <Button 
                variant="contained" 
                color="primary" 
                component="span"
              >
                Select .csv file
              </Button>
            </label>

            <input
              style={{height: 0, width: 0}}
              id="file"
              type='file'
              accept='.csv'
              onChange={handleSelectFileChange}
            /> 
          </>
        )
      }
    </>        
  )
}

export default FileUpload