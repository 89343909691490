import React, { FC, useState } from 'react'
import { Line } from "react-chartjs-2"
import styled from 'styled-components'
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination
} from 'react-table'

import Button from '@material-ui/core/Button'

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(
          (row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )}
        )}
      </tbody>
    </table>
  )
}

interface Props {
  chartData: object
  tableData: Array<object>
  onClose: () => void
  metadata: any
}

const Chart: FC<Props> = ({
  chartData,
  tableData,
  onClose,
  metadata
}) => {
  const [displayAsTable, setDisplayAsTable] = useState(false)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Weekday',
        accessor: 'weekday'
      },
      {
        Header: 'Weekhour',
        accessor: 'weekhour'
      },
      {
        Header: 'Actual',
        accessor: 'actual'
      },
      {
        Header: 'Average',
        accessor: 'average'
      },
      {
        Header: 'UCL',
        accessor: 'UCL'
      },
      {
        Header: 'LCL',
        accessor: 'LCL'
      },
      {
        Header: 'UCL95',
        accessor: 'UCL99'
      },
      {
        Header: 'LCL5',
        accessor: 'LCL1'
      },
      {
        Header: 'Last hour',
        accessor: 'lasthour'
      },
       {
        Header: `High Anomaly (${metadata.highAnomalyCount})`,
        accessor: 'evPlus'
      },   
       {
        Header: `Low Anomaly (${metadata.lowAnomalyCount})`,
        accessor: 'evMinus'
       }
    ],
    []
  )

  return (
    <>
      <div>
        <h2 style={{display:'inline-block'}}>
          IT-Control Chart
        </h2>
        <Button 
          variant="outlined"
          size="small"
          onClick={onClose}
          style={{display: 'inline-block', marginLeft: '10px'}}
        >
          Back
        </Button>
      </div>
      
      <h4 style={{display: 'inline-block'}}>
        Data Weekly Profile
      </h4>
      <Button 
        variant='outlined'
        color='primary'
        size='small'
        onClick={() => setDisplayAsTable(!displayAsTable)}
        style={{display: 'inline-block', marginLeft: '10px'}}
      >
        {displayAsTable ? 'view as chart' : 'view as table'}
      </Button>

      {!displayAsTable &&
        <>
          <div className="chart">
            <Line
              data={chartData}
              options={{
                fill: false,
                title: {
                  display: true,
                  fontSize: 25
                },
                legend: {
                  display: true,
                  position: "bottom"
                }
              }}
            />
          </div>

          <h4>Legend</h4>

        <legend>
          <ul>
            <li>actual - most recent 7 days data values</li>
            <li>average - the average of values observed in paticular week hour</li>
            <li>lasthour - 0 if actual value is not a last observation.</li>
          </ul>

          <strong>For values observed in paticular week hour:</strong>
            <ul>
              <li>UCL - 3 standard deviation based Upper Control Limit</li>
              <li>LCL - 3 standard deviation based Lower Control Limit</li>
              <li>UCL95 - 95 percentile based Upper Control Limit</li>
              <li>LCL5 - 5 percentile based Upper Control Limit</li>
            </ul>
        </legend>
        
        <p>
          <strong>Hint:</strong>
          &nbsp;
          <span>
            anomalies can be observed visually where <code>actual &gt; UCL</code> or <code>actual &lt; LCL</code>
          </span>
        </p>
      </>
}  
      { displayAsTable &&

      
        <Styles>
        <Table 
          columns={columns} data={tableData} 
        />

        </Styles>
}
      
    
    </>
  )
}

export default Chart
